import { useId } from '@react-aria/utils';
import { useDOMRef, useValueEffect } from '@react-spectrum/utils';
import { TabListState } from '@react-stately/tabs';
import { DOMRef } from '@react-types/shared';
import * as React from 'react';

import { splitBoxProps } from '../../utils';
import { PolymorphicComponentProps } from '../Box';
import { NoSsr } from '../NoSsr';
import { Stack } from '../Stack';
import { TabsContext } from './TabsContext';
import { AppearanceVals, OrientationVals, VerticalStateAlignment } from './variants';

export type TabsOwnProps = {
  /**
   * Must contain `TabList` and `TabPanel` components.
   */
  children: React.ReactNode;

  /**
   * The current selected tab's `id`.
   * If provided and `onChange` is not provided, this value acts as a default value.
   */
  selectedId?: string;

  /**
   * Callback for when the selected tab state changes.
   * When provided you are expected to manage `selectedId` (controlled mode).
   */
  onChange?: (selectedId: string) => void;

  /**
   * Defines the direction the tabs are displayed. Defaults to `horizontal`.
   */
  orientation?: OrientationVals;

  /**
   * Alter the tabs overall appearance - defaults to `minimal`.
   */
  appearance?: AppearanceVals;

  activeStateAlignment?: VerticalStateAlignment;
};

export type TabProps<E extends React.ElementType = 'div'> = PolymorphicComponentProps<E, TabsOwnProps>;

export const Tabs: <E extends React.ElementType = 'div'>(props: TabProps<E>) => JSX.Element = React.forwardRef(
  function Tabs(props, ref: DOMRef<HTMLDivElement>) {
    const { children, appearance = 'minimal', orientation = 'horizontal', ...rest } = props;
    const { matchedProps } = splitBoxProps(rest);

    const domRef = useDOMRef(ref);
    const tablistRef = React.useRef<HTMLDivElement>();
    const wrapperRef = React.useRef<HTMLDivElement>();

    const [collapse, setCollapse] = useValueEffect(false);
    const [selectedTab, setSelectedTab] = React.useState<HTMLElement>();
    const [tabListState, setTabListState] = React.useState<TabListState<any>>(null);

    React.useEffect(() => {
      if (tablistRef.current) {
        let selectedTab: HTMLElement = tablistRef.current.querySelector(`[data-key="${tabListState?.selectedKey}"]`);

        if (selectedTab != null) {
          setSelectedTab(selectedTab);
        }
      }
      // collapse is in the dep array so selectedTab can be updated for TabLine positioning
    }, [children, tabListState?.selectedKey, collapse, tablistRef]);

    let tabPanelProps = {
      'aria-labelledby': undefined,
    };

    // When the tabs are collapsed, the tabPanel should be labelled by the Picker button element.
    let collapsibleTabListId = useId();
    if (collapse && orientation !== 'vertical') {
      tabPanelProps['aria-labelledby'] = collapsibleTabListId;
    }

    return (
      <NoSsr>
        <TabsContext.Provider
          value={{
            tabsProps: { ...props, orientation, appearance },
            tabState: { tabListState, setTabListState, selectedTab, collapse },
            refs: { tablistRef, wrapperRef },
            tabPanelProps,
          }}
        >
          <Stack
            ref={domRef}
            w="full"
            direction={orientation === 'vertical' ? 'horizontal' : 'vertical'}
            h={orientation === 'vertical' ? 'full' : 'auto'}
            spacing={1}
            {...matchedProps}
          >
            {props.children}
          </Stack>
        </TabsContext.Provider>
      </NoSsr>
    );
  },
);
