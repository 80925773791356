/**
 * Adapted from Material UI - https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/NoSsr/NoSsr.js
 */

import * as React from 'react';

import { useSafeEffect } from '../../hooks/use-safe-effect';

export type NoSsrProps = {
  defer?: boolean;
  fallback?: React.ReactNode;
};

/**
 * NoSsr purposely removes components from the subject of Server Side Rendering (SSR).
 *
 * This component can be useful in a variety of situations:
 * - Escape hatch for broken dependencies not supporting SSR.
 * - Improve the time-to-first paint on the client by only rendering above the fold.
 * - Reduce the rendering time on the server.
 * - Under too heavy server load, you can turn on service degradation.
 */
export const NoSsr: React.FC<NoSsrProps> = props => {
  const { children, defer = false, fallback = null } = props;
  const [mountedState, setMountedState] = React.useState(false);

  useSafeEffect(() => {
    if (!defer) {
      setMountedState(true);
    }
  }, [defer]);

  React.useEffect(() => {
    if (defer) {
      setMountedState(true);
    }
  }, [defer]);

  return <>{mountedState ? children : fallback}</>;
};
